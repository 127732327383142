exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-casos-de-estudio-js": () => import("./../../../src/pages/casos-de-estudio.js" /* webpackChunkName: "component---src-pages-casos-de-estudio-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-tags-blog-template-js": () => import("./../../../src/templates/tagsBlogTemplate.js" /* webpackChunkName: "component---src-templates-tags-blog-template-js" */),
  "component---src-templates-tags-casos-template-js": () => import("./../../../src/templates/tagsCasosTemplate.js" /* webpackChunkName: "component---src-templates-tags-casos-template-js" */)
}

